import gql from 'graphql-tag'

export const allAggFragment = gql`
  fragment allAgg on TariffsResult {
    agg {
      allAvailableServices {
        choices
        id
        name
      }
      allAvailableServiceGroups {
        id
        serviceIds
        name
        servicesChoices
      }
      hasFastConnection
      availableHouseTypes
      hasGaming
      hasPromo
      hasIncludedDeviceKinds
      hasFreeConnection
      hasWifiTariffs
      hasIncludedDeviceKinds
      availableTechs {
        id
        name
      }
      availableProviders {
        id
        logo
        name
        slug
        logoT {
          url(options: { width: 80, halign: "left" })
          unoptimized
        }
      }
      priceRange {
        max
        min
      }
      speedRange {
        max
        min
      }
      providersCount
      tariffsCount
      availableServices {
        choices
        id
        name
      }
      availableSubscriptionServices {
        id
        name
        icon
        slug
      }
      availableServiceGroups {
        serviceIds
        name
        id
      }
      locationAgg {
        hasGaming
        hasPromo
        hasIncludedDeviceKinds
        hasFreeConnection
        hasWifiTariffs
        hasIncludedDeviceKinds
        hasFastConnection
        availableHouseTypes
        speedRange {
          max
          min
        }

        availableSmartFilters {
          slug
          name
          tariffsInput
          asQueryParam
        }
        tariffsCount
        availableServiceGroups {
          id
          serviceIds
          name
          servicesChoices
        }
        hasGaming
        availableTechs {
          id
          name
        }
        availableProviders {
          id
          logo
          name
          slug
          logoT {
            url(options: { width: 80, halign: "left" })
          unoptimized
          }
        }
        priceRange {
          max
          min
        }
        availableServices {
          choices
          id
          name
        }
        availableSubscriptionServices {
          id
          name
          icon
          slug
        }
      }
    }
  }
`
export const providerInTariffFragment = gql`
  fragment providerInTariff on Tariff {
    provider {
      id
      slug
      name
      logoT {
        url(options: { width: 300, halign: "left" })
        unoptimized
      }
    }
  }
`
export const mainTariffFragment = gql`
  fragment mainTariff on Tariff {
    id
    name
    index
    paramHash 
    markers {
      cheaper
      optimal
      recommended
      topSale
      fakeMobile
      fake
      enableAutomatization
      disabledAutomatizationReasonsDisplay
      disabledAutomatizationReasons
      providerRequirements {
        birthday
        connectionDate
        entrance
        floor
      }
    }
    houseType
    sortDebug
    wireless
    connectionTime {
      displayShort
      isFast
    }
    payment {
      price
      promoPeriod
      promoPrice
      complexPrice {
        price
        fromMonth
        promoDisplay
        promoDisplayPercents
        isMainPrice
        isPromoPrice
      }
      promoDisplayPercents
      prepaidPayment
      creditPayment
      contractPeriod
      freeConnection
      installPayment
            installPaymentVaries
            installPaymentMax
    }
    services {
      mobileSimCount
      tvChannelsCount
      mobileSms
      mobileMinutes
      mobileGigabytes
      internetSpeed
      internetPortSpeed
      internetEffectiveSpeed
      cctvCameraCount
      cctvStorageDays
      wirelessInternetGb
    }
    subscriptions {
      subscription {
        name
        id
        iconSmall
        slug
        shortDescription
        includes {
          id
          iconSmall
          slug
        }
      }
    }
    techs {
      id
      name
    }
    devices(onlySelected: true) {
      device {
        kind
        id
        wifi
      }
      recommended
      mainPaymentMethod {
        method
        monthlyPayment
        cost
      }
    }
  }
`
export const getTariffs = gql`
  ${providerInTariffFragment}
  ${mainTariffFragment}
  ${allAggFragment}
  query getTariffs(
    $input: LocationInfoInput!
    $tariffsInput2: BaseTariffsInput
  ) {
    location {
      locationTerms(input: $input) {
        tariffs(input: $tariffsInput2) {
          hasNext
          totalItems
          popularDisplayHint
          sortDebug
          data {
            ...mainTariff
            ...providerInTariff
            info {
              attributes {
                id
                key
                name
              }
              benefits: byType(type: [BENEFIT]) {
                name
                id
              }
              limited: byType(type: [LIMITED]) {
                id
                name
                content
              }
              byType(type: [BONUSES, SPECIAL_PROMOS, GAMING, UNLIMITED]) {
                id
                name
                icon
              }
            }
            fromAddress
          }
          ...allAgg
        }
      }
    }
  }
`
export const getFilters = gql`
  ${allAggFragment}
  query getFilters(
    $input: LocationInfoInput!
    $tariffsInput2: BaseTariffsInput
  ) {
    location {
      locationTerms(input: $input) {
        tariffs(input: $tariffsInput2) {
          ...allAgg
        }
      }
    }
  }
`
export const getProvidersWithTariffs = gql`
  ${providerInTariffFragment}
  ${mainTariffFragment}
  ${allAggFragment}
  query getProvidersWithTariffs(
    $input: LocationInfoInput!
    $tariffsInput2: BaseTariffsInput
    $providersInput2: BaseLocationProvidersInput
  ) {
    location {
      locationTerms(input: $input) {
        location {
          hash
          autoGeneratedUrl
          linkHousesCount
          houseType
        }
        providers(input: $providersInput2) {
          providers {
            score
            ratings {
              ratingFinal
              reviewsCount
            }
            bestTariffs {
              ...mainTariff
              ...providerInTariff
              info {
                attributes {
                  id
                  key
                  name
                }
                benefits: byType(type: [BENEFIT]) {
                  name
                  id
                }
                limited: byType(type: [LIMITED]) {
                  id
                  name
                  content
                }
                byType(type: [BONUSES, SPECIAL_PROMOS, GAMING, UNLIMITED]) {
                  id
                  name
                  icon
                }
              }
              connectionTime {
                displayRange
                displayMin
                displayAvg
                fastestConnectionProvider
              }
              fromAddress
            }
            agg {
              availableHouseTypes
              hasFastConnection
              availableServices {
                id
                name
              }
              locationAgg {
                availableHouseTypes
                rawPriceRange {
                  min
                }
                availableServices {
                  id
                  name
                }
                availableSmartFilters {
                  slug
                  name
                  tariffsInput
                }
                hasGaming
                availableSubscriptionServices {
                  id
                }
              }
              rawPriceRange {
                min
              }
              hasGaming
              mostlyFreeConnection
              availableSubscriptionServices {
                id
              }
              promoCount
              tariffsCount
              priceRange {
                min
              }
              speedRange {
                max
              }
            }
            connectionTime {
              displayShort
            }
            provider {
              logo
              id
              name
              slug
              promoDisplay
              config
              cardDescription
              logoT {
                url(options: { width: 300, halign: "left" })
          unoptimized
              }
            }
            housesCount
            coverage {
              mkdCoverage
            }
          }
          totalItems
        }
        tariffs(input: $tariffsInput2) {
          hasNext
          totalItems
          popularDisplayHint
          sortDebug
          ...allAgg
        }
      }
    }
  }
`
export const getBestProviders = gql`
  ${mainTariffFragment}
  ${providerInTariffFragment}
  query getBestProviders(
    $input: LocationInfoInput!
    $groupInput2: BaseTariffsInput
    $groupInput3: BaseTariffsInput
    $groupInput4: BaseTariffsInput
  ) {
    location {
      locationTerms(input: $input) {
        location {
          hash
          autoGeneratedUrl
        }
        groupedTariffs {
          popular: group(input: $groupInput2) {
            data {
              ...mainTariff
              ...providerInTariff
              info {
                attributes {
                  id
                  key
                  name
                }
                benefits: byType(type: [BENEFIT]) {
                  name
                  id
                }
                limited: byType(type: [LIMITED]) {
                  id
                  name
                  content
                }
                byType(type: [BONUSES, SPECIAL_PROMOS, GAMING, UNLIMITED]) {
                  id
                  name
                  icon
                }
              }
              connectionTime {
                displayRange
                displayMin
                displayAvg
                fastestConnectionProvider
              }
              fromAddress
            }
          }
          cheap: group(input: $groupInput3) {
            data {
              ...mainTariff
              ...providerInTariff
              info {
                attributes {
                  id
                  key
                  name
                }
                benefits: byType(type: [BENEFIT]) {
                  name
                  id
                }
                limited: byType(type: [LIMITED]) {
                  id
                  name
                  content
                }
                byType(type: [BONUSES, SPECIAL_PROMOS, GAMING, UNLIMITED]) {
                  id
                  name
                  icon
                }
              }
              connectionTime {
                displayRange
                displayMin
                displayAvg
                fastestConnectionProvider
              }
              fromAddress
            }
          }
          speed: group(input: $groupInput4) {
            data {
              ...mainTariff
              ...providerInTariff
              info {
                attributes {
                  id
                  key
                  name
                }
                benefits: byType(type: [BENEFIT]) {
                  name
                  id
                }
                limited: byType(type: [LIMITED]) {
                  id
                  name
                  content
                }
                byType(type: [BONUSES, SPECIAL_PROMOS, GAMING, UNLIMITED]) {
                  id
                  name
                  icon
                }
              }
              connectionTime {
                displayRange
                displayMin
                displayAvg
                fastestConnectionProvider
              }
              fromAddress
            }
          }
        }
        tariffs {
          agg {
            tariffsCount
          }
        }
      }
    }
  }
`
export const getSpeedTestTariffs = gql`
  ${mainTariffFragment}
  ${providerInTariffFragment}
  query getSpeedTestTariffs(
    $input: LocationInfoInput!
    $groupInput2: BaseTariffsInput
  ) {
    location {
      locationTerms(input: $input) {
        location {
          hash
          autoGeneratedUrl
        }
        groupedTariffs {
          speed: group(input: $groupInput2) {
            data {
              ...mainTariff
              ...providerInTariff
            }
          }
        }
      }
    }
  }
`
export const getHouseTypes = gql`
  query getHouseTypes($input: LocationInfoInput!) {
    location {
      locationTerms(input: $input) {
        tariffsAgg {
          availableHouseTypes
        }
      }
    }
  }
`
export const getOneProvider = gql`
  query getOneProvider(
    $input: LocationInfoInput!
    $providersInput2: BaseLocationProvidersInput
  ) {
    location {
      locationTerms(input: $input) {
        providers(input: $providersInput2) {
          ratings {
            provider {
              logo
              id
              name
              slug
              promoDisplay
              cardTitle
              cardDescription
              config
              logoT {
                url(options: { width: 300, halign: "left" })
                unoptimized
              }
            }
            ratingFinal
            reviewsCount
          }
          
          providers {
            score
            agg {
              availableServices {
                id
                name
              }
              availableServiceGroups {
                id
                serviceIds
                name
                servicesChoices
              }
              hasGaming
              availableSubscriptionServices {
                id
                name
              }
              tariffsCount
              priceRange {
                min
              }
              speedRange {
                max
              }
              locationAgg {
                hasGaming
                hasPromo
                hasIncludedDeviceKinds
                hasFreeConnection
                hasWifiTariffs
                hasIncludedDeviceKinds
                speedRange {
                  max
                  min
                }
                availableSmartFilters {
                  name
                  slug
                  tariffsInput
                }
                tariffsCount
                availableServiceGroups {
                  id
                  serviceIds
                  name
                  servicesChoices
                }
                hasGaming
                availableTechs {
                  id
                  name
                }
                availableProviders {
                  id
                  logo
                  name
                  slug
                  logoT {
                    url(options: { width: 80, halign: "left" })
                    unoptimized
                  }
                }
                priceRange {
                  max
                  min
                }
                availableServices {
                  choices
                  id
                  name
                }
                availableSubscriptionServices {
                  id
                  name
                  icon
                  slug
                }
              }
            }
            provider {
              id
            }
          }
        }
      }
    }
  }
`
export const getProviderDevices = gql`
  query getProviderDevices(
    $input: LocationInfoInput!
    $providersInput2: BaseLocationProvidersInput
  ) {
    location {
      locationTerms(input: $input) {
        providers(input: $providersInput2) {
          providers {
            devices {
              data {
                device {
                  id
                  description
                  image
                  kind
                  kindName
                  name
                  wifi
                }
                paymentMethods {
                  cost
                  method
                  monthlyPayment
                  singlePayment
                }
              }
            }
          }
        }
      }
    }
  }
`
